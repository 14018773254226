import styled, { css } from 'styled-components'
import usBg from './../../assets/us/bgpicture.svg'
import { down } from 'styled-breakpoints'

export const Background = styled.div`
  background-image: url(${usBg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  ${down('sm')} {
    padding-left: 20px;
    height: 80vh;
  }
`

export const Column = styled.div`
  flex: 1;
  padding: 16px;
  padding-left: 100px;
  text-align: center;
  ${down('sm')} {
    padding-left: 20px;
  }
`

export const Text = styled.p`
  font-family: Outfit;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  ${(props) =>
    props.title &&
    css`
      ${down('sm')} {
        font-size: 29px;
        height: 130px;
        margin-bottom: 0px;
      }
      margin-bottom: -100px;
      font-size: 51px;
      font-weight: 800;
      line-height: 1.25;
      text-align: center;
      text-align: center;
      background-color: #fbed1d;
      width: 100%;
      height: 200px;
      clip-path: polygon(
        30% 1%,
        70% 1%,
        99% 1%,
        94% 33%,
        69% 32%,
        32% 32%,
        0 33%,
        8% 2%
      );
    `}
  ${(props) =>
    props.icon &&
    css`
      margin-top: -10px;
    `}
`

export const TitleContainer = styled.div`
  padding: 0 50px;
  ${down('sm')} {
    padding: 0 20px;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Image = styled.img`
  width: 278px;
  height: 416.2px;
  ${(props) =>
    props.icon &&
    css`
      width: 120.4px;
      height: 120.4px;
      margin-top: -65px;
      margin-left: 50%;
      transform: translateX(-50%);
    `}
`

export const Picture = styled.img`
  width: 415px;
  height: 233px;
  object-fit: contain;
  ${(props) =>
    props.border &&
    css`
      margin-top: -10px;
      border-radius: ${props.border};
      width: ${props.width};
      height: ${props.height};
    `}
  ${down('sm')} {
    width: 216.4px;
    height: 121.7px;
  }
`

export const PictureContainer = styled.div`
  display: flex;
  ${down('sm')} {
    justify-content: center;
    ${(props) =>
      props.padding &&
      css`
        padding: ${props.padding};
      `}
  }
`
export const Paragraph = styled.p`
  font-family: Outfit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-bottom: 30px;
`
