import React from 'react'
import Layout from '../../components/Layout'
import UsBottom from './UsBottom'
import UsHeader from './UsHeader'
import { down } from 'styled-breakpoints'
import { useBreakpoint } from 'styled-breakpoints/react-styled'

const Us = () => {
  const isXs = useBreakpoint(down('sm'))
  return (
    <Layout isXs={isXs}>
      <UsHeader isXs={isXs} />
      <UsBottom isXs={isXs} />
    </Layout>
  )
}

export default Us
