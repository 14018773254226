import HomePage from './modules/HomePage'
import Process from './modules/Process/Process'
import Services from './modules/Services/ServicesPage/Services'
import Us from './modules/Us/Us'

export const routes = [
  {
    path: '/',
    public: true,
    component: HomePage,
  },
  {
    path: '/services',
    public: true,
    component: Services,
  },
  {
    path: '/process',
    public: true,
    component: Process,
  },
  {
    path: '/us',
    public: true,
    component: Us,
  },
]
