import styled from 'styled-components'
import customerBg from './../../assets/customerbg.svg'

export const Container = styled.div`
  background-image: url(${customerBg});
  background-size: cover;
  width: 100%;
  height: 100%;
  margin-top: -90px;
`

export const ImageContainer = styled.div`
  width: 500px;
  height: 50px;
`
