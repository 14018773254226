import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  ImageContainer,
  SlideImg,
  SlideTxt,
  TextContainer,
} from './slyderStyled'

const SliderBanner = ({ data }) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 10000,
    autoplay: true,
    autoplaySpeed: 1000,
    slidesToShow: 5,
    slidesToScroll: 5,
    pauseOnHover: false,
    cssEase: 'linear',
    arrows: false,
  }
  return (
    <div style={{ width: '100%' }}>
      <Slider {...settings}>
        {data.map((d) =>
          d.image ? (
            <div key={d.id}>
              <ImageContainer>
                <SlideImg src={d.image} alt={d.name} />
              </ImageContainer>
            </div>
          ) : (
            <div key={d.id}>
              <TextContainer>
                <SlideTxt>{d.text}</SlideTxt>
              </TextContainer>
            </div>
          ),
        )}
      </Slider>
    </div>
  )
}

export default SliderBanner
