import React from 'react'
import { Col, Row } from '../../components/Styled/GlobalStyles'
import StaticsTexts from './StaticsTexts'

const Statics = ({ isXs }) => {
  return (
    <Row
      style={
        isXs
          ? { padding: '0', margin: '0' }
          : { padding: '0 250px', margin: '30px 0' }
      }
    >
      <Col size={isXs ? 12 : 4}>
        <StaticsTexts nTxt='1' undNum='500' undTxt='Videos' />
      </Col>
      <Col size={isXs ? 6 : 4}>
        <StaticsTexts undNum='150' undTxt='Clientes' />
      </Col>
      <Col size={isXs ? 6 : 4}>
        <StaticsTexts undNum='4M' undTxt='Vistas' />
      </Col>
    </Row>
  )
}

export default Statics
