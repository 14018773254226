import React from 'react'
import { Col, Row } from '../../components/Styled/GlobalStyles'
import image from './../../assets/us/btmPic.svg'
import image2 from './../../assets/us/btmPic-2.svg'
import image3 from './../../assets/us/btmPic-3.svg'
import image4 from './../../assets/us/btmPic-4.svg'
import image5 from './../../assets/us/btmPic-5.svg'
import { Paragraph, Picture, PictureContainer } from './usStyled'
import { ContactBtn } from '../Services/styledServices'

const UsBottom = ({ isXs }) => {
  return (
    <Row
      style={
        isXs
          ? {
              backgroundColor: '#222222',
              paddingBottom: '150px',
              flexDirection: 'column-reverse',
            }
          : { backgroundColor: '#222222', paddingBottom: '150px' }
      }
    >
      <Col
        style={
          isXs
            ? { textAlign: 'center', marginLeft: '0px', marginTop: '-40px' }
            : {
                textAlign: 'center',
                marginLeft: '100px',
                marginTop: '-40px',
              }
        }
      >
        <Picture src={image} alt='crowneo crew' />
        <PictureContainer padding='0 34px'>
          <Picture
            border='120px 0 0 0'
            width={isXs ? '158px' : '304px'}
            height={isXs ? '118.5px' : '228px'}
            src={image2}
            alt='crowneo crew'
          />
          <Picture
            border='0 0 120px 0'
            width={isXs ? '158px' : '304px'}
            height={isXs ? '118.5px' : '228px'}
            src={image3}
            alt='crowneo crew'
          />
        </PictureContainer>
        <PictureContainer padding='0 76px'>
          <Picture
            border='0 0 120px 0'
            width={isXs ? '164.8' : '316px'}
            height={isXs ? '140px' : '268px'}
            src={image4}
            alt='crowneo crew'
          />
          <Picture
            border='0 120px 0 0'
            width={isXs ? '127.8px' : '245px'}
            height={isXs ? '170.4px' : '327px'}
            src={image5}
            alt='crowneo crew'
          />
        </PictureContainer>
      </Col>
      <Col
        style={
          isXs
            ? { padding: '0 50px', marginTop: '100px', marginBottom: '100px' }
            : { padding: '0 170px', marginTop: '100px' }
        }
      >
        <Paragraph>
          A diferencia de otras casas productoras trabajamos como si formáramos
          parte de tu empresa, para que uses estos videos de manera más
          eficiente y obtengas resultados que hagan crecer tu proyecto.
        </Paragraph>
        <Paragraph>
          El tiempo es esencial y nos enorgullecemos de cumplir SIEMPRE con los
          plazos. (Incluso con poca anticipación), sin comprometer la calidad de
          la producción.
        </Paragraph>
        <Paragraph>
          También entendemos que los presupuestos pueden ser ajustados, por lo
          que ofrecemos una variedad de paquetes que se adaptan a sus
          necesidades.
        </Paragraph>
        <Paragraph>
          Siempre estamos actualizados y tratamos de implementar ideas
          originales y una estética moderna para que tu video tenga más
          vigencia.
        </Paragraph>
        <ContactBtn process={true} fSize='15px'>Conoce como podemos ayudarte</ContactBtn>
      </Col>
    </Row>
  )
}

export default UsBottom
