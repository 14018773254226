import styled from 'styled-components'

export const TextContainer = styled.div`
  padding: 0 20px;
`

export const Paragraph = styled.p`
  font-family: Outfit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #000;
`
export const Author = styled.p`
  font-family: Outfit;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: right;
  color: #000;
`
