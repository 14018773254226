import styled from 'styled-components'

export const NumberText = styled.div`
  font-family: Outfit;
  font-size: 51px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #000;
`

export const UnderText = styled.div`
  font-family: Outfit;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #000;
`

export const Underline = styled.span`
  text-decoration: underline solid #fbed1d;
  text-decoration-thickness: 6px;
`
