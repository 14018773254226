import React from 'react'
import {
  Logo,
  Nav,
  NavItem,
  NavItemLogo,
  NavLink,
  NavLinkScroll,
  NavList,
} from './styledNavbar'
import logo from './../../assets/crowneo_navbar.svg'
import { down } from 'styled-breakpoints'
import { useState } from 'react'
import MobileNavbar from './styledHamburger'
import { useBreakpoint } from 'styled-breakpoints/react-styled'

const Navbar = () => {
  const isXs = useBreakpoint(down('sm'))
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {isXs ? (
        <MobileNavbar />
      ) : (
        <Nav>
          <NavList>
            <NavItemLogo>
              <NavLink href='#'>
                <Logo src={logo} alt='crowneo-logo' />
              </NavLink>
            </NavItemLogo>
            <NavItem>
              <NavLink href='#'>DEMO</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='/#/services'>SERVICIO</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='/#/process'>PROCESO</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='/#/us'>NOSOTROS</NavLink>
            </NavItem>
            <NavItem bg={true}>
              <NavLinkScroll
                activeClass='active'
                to='contactSection'
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                CONTACTO
              </NavLinkScroll>
            </NavItem>
          </NavList>
        </Nav>
      )}
    </>
  )
}

export default Navbar
