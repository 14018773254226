import styled, { css } from 'styled-components'
import { Field } from 'formik'
import contactImg from '../../assets/contactLogo.svg'
import { down } from 'styled-breakpoints'
import { FaWhatsapp } from 'react-icons/fa'

export const Label = styled.label`
  font-family: Outfit;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #000;
`

export const StyledField = styled(Field)`
  width: 100%;
  height: ${(props) => (props.textArea ? '100px' : '34px')};
  border: 3px solid #000;
  border-radius: 5px;
  padding: 0 10px;
  margin-bottom: 10px;
  margin-top: 5px;
`
export const Button = styled.button`
  margin-top: 25px;
  width: 50%;
  height: 34px;
  border-radius: 5px;
  border: 5px solid #000;
  background-color: #fff100;
  font-family: Outfit;
  font-size: 21px;
  font-weight: 800;
  color: #000;
  position: absolute;
  right: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #fff766;
    color: #000;
  }
  ${down('sm')} {
    margin-top: 45px;
    font-size: 15px;
  }
`

export const Title = styled.h3`
  font-family: Outfit;
  font-size: 75px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  position: relative;
  color: #000;
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const YellowLight = styled.div`
  width: 237.5px;
  height: 34.4px;
  background-color: #fbed1f;
  position: absolute;
  top: 50px;
  z-index: -3;
`

export const SubTitle = styled.h4`
  font-family: Outfit;
  font-size: 18px;
  position: relative;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.44;
  letter-spacing: normal;
  text-align: center;
  margin-top: -20px;
  color: #000;
`
export const BgColor = styled.div`
  position: relative;
  z-index: -9;
  width: 100%;
  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};
    `}
  ${({ border }) =>
    border &&
    css`
      border-radius: ${border};
    `}
    ${({ yellow }) =>
    yellow &&
    css`
      height: 100%;
      background-image: url(${contactImg});
      background-repeat: no-repeat;
      background-position: -200px 30px;
    `}
`
export const InfoText = styled.p`
  font-family: Outfit;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  color: #000;
  font-size: ${({ fSize }) => (fSize ? fSize : '41px')};
`
export const Icon = styled.img`
  width: 83.6px;
  height: 83.6px;
  position: absolute;
  right: 150px;
  bottom: 80px;
`

export const FormContainer = styled.div`
  padding: 0 250px;
  ${down('sm')} {
    padding: 0 50px;
  }
`
// **************************************************************
// responsive
// **************************************************************
export const BannerContainer = styled.div`
  background-color: #fff100;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  border-radius: 0 40% 0 0;
  width: 100%;
`

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
`
export const RespText = styled.p`
  font-family: Outfit;
  font-size: 25px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  margin: 0 10px;
  padding-top: 10px;
`

export const BorderText = styled.p`
  opacity: 1;
  font-family: Outfit;
  font-size: 9px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: right;
  color: #000;
  padding-top: 10px;

`
export const RespIcon = styled(FaWhatsapp)`
  font-size: 30px;
`

export const Logito = styled.img`
  position: absolute;
  left: -30px;
  margin-top: -40px;
`
