import React from 'react'
import { Row } from '../../components/Styled/GlobalStyles'
import { ContactBtn } from '../Services/styledServices'
import {
  ProcessContainer,
  TextRow,
  TwoColumnRow,
  Column,
  Text,
} from './separatorStyled'

const ProcessInfo = () => {
  return (
    <>
      <ProcessContainer>
        <TwoColumnRow>
          <Column>
            <Text title={true}>Pre.</Text>
            <Text>
              En pre-producción es donde se da toda la planeación. Identificamos
              el objetivo y tus necesidades, desarrollamos un mensaje que cumpla
              con todas tus expectativas y creamos un plan al rededor de dicho
              mensaje. Después empezamos a trabajar sobre la dirección de arte,
              casting de talentos, scouting de locaciones y crear el calendario
              de producción.
            </Text>
          </Column>
          <Column>
            <Text title={true}>Pro.</Text>
            <Text>
              Hora de divertirnos, en producción es cuando sacamos las cámaras,
              luces y empezamos a crear. Ya sea en locación o en nuestro
              estudio, se captura cada detalle y cuidamos que todo salga de
              acuerdo a lo planeado (aunque también estamos listos para resolver
              cualquier imprevisto).
            </Text>
          </Column>
        </TwoColumnRow>
        <TextRow>
          <Text title={true}>Post.</Text>
          <Text>
            En esta parte es cuando agregamos la magia, como la corrección de
            color, la mezcla de audio y creamos animaciones 2D y 3D necesarias
            para que tu proyecto quede en otro nivel.
            <br />
            <br />
            Al final te entregamos los archivos digitales de acuerdo a la
            plataforma en la que quieras publicar para alcanzar tu audiencia, ya
            sea en tu pagina web o tus redes sociales. También respaldamos tu
            material por si quisieras crear algo diferente en el futuro.
          </Text>
        </TextRow>
        <TextRow>
          <Text title={true}>Siempre a tu lado</Text>
          <Text>
            Desde el inicio y hasta concluir el proyecto te guiaremos para que
            tu proyecto se realice sin ninguna dificultad, resolveremos tus
            dudas y te haremos recomendaciones como tu mejor amigo lo haria.
            <br />
            <br />
            Nos tomamos muy en serio que tu video sea un éxito, así que déjalo
            en nuestras manos y hagamos que tu competencia tiemble
          </Text>
        </TextRow>
        <TextRow>
          <ContactBtn process={true}>
            Mira los proyectos que hemos hecho
          </ContactBtn>
        </TextRow>
      </ProcessContainer>
    </>
  )
}

export default ProcessInfo
