import styled, { css } from 'styled-components'
import { Link } from 'react-scroll'

export const NavLinkScroll = styled(Link)`
  cursor: pointer;
  font-family: Outfit;
  font-size: 22px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  color: #000;
  text-decoration: none;
  text-align: left;
  &:hover {
    color: #ccc;
  }
`

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  margin-bottom: -20px;
  padding: 0 140px;
`

export const NavList = styled.ul`
  display: flex;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const NavItem = styled.li`
  width: 100%;
  margin-left: 1rem;
  padding-top: 35px;
  text-align: center;
  ${(props) =>
    props.bg &&
    css`
      margin: 28px 87px 57px 37.3px;
      padding: 6.7px 19.8px 7.3px 16.9px;
      border-radius: 10% 10% 10% 10% / 10% 70% 10% 80%;
      /* border-radius: 10% 70% 10% 80%/10% 10% 10% 10%; */
      /* border-radius: 80% 10% 70% 10%/10% 10% 10% 10%; */
      background-color: #fbed1d;
    `}
`

export const NavItemLogo = styled.li`
  & + & {
    margin-left: 1rem;
  }
`

export const NavLink = styled.a`
  font-family: Outfit;
  font-size: 22px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  color: #000;
  text-decoration: none;
  text-align: left;
  &:hover {
    color: #ccc;
  }
`

export const Logo = styled.img`
  width: 288px;
  height: 57px;
  padding: 25px;
  background-color: transparent;
`
