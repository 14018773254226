import styled from 'styled-components'
import { down } from 'styled-breakpoints'

export const BannerContainer = styled.div`
  background-color: yellow;
  padding: 35px;
  text-align: center;
  justify-content: space-between;
  display: flex;
`
export const BannerText = styled.p`
  opacity: 0.42;
  font-family: Outfit;
  font-size: 52px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  ${down('sm')} {
    font-size: 40px;
    opacity: 1;
    margin-top: 10px;
    ${(props) => props.title && 'font-size: 34px; margin-bottom: 20px;'}
    ${(props) => props.pizza && 'margin-left: 50%'}
  }
`
