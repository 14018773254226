import React from 'react'
import { down } from 'styled-breakpoints'
import { useBreakpoint } from 'styled-breakpoints/react-styled'
import SliderBanner from '../../components/Slide/Slider'
import BannerMobile from './BannerMobile'
import { BannerContainer, BannerText } from './bannerStyled'

const texts = [
  {
    id: 1,
    text: 'Videos',
  },
  {
    id: 2,
    text: 'Musicales',
  },
  {
    id: 3,
    text: 'Comerciales',
  },
  {
    id: 4,
    text: 'Reels',
  },
  {
    id: 5,
    text: 'Sociales',
  },
  {
    id: 6,
    text: 'Videos',
  },
  {
    id: 7,
    text: 'Musicales',
  },
  {
    id: 8,
    text: 'Comerciales',
  },
  {
    id: 9,
    text: 'Reels',
  },
  {
    id: 10,
    text: 'Sociales',
  },
]

const Banner = () => {
  const isXs = useBreakpoint(down('sm'))
  return (
    <BannerContainer>
      {!isXs ? (
        <>
          <SliderBanner data={texts} />
        </>
      ) : (
        <BannerMobile />
      )}
    </BannerContainer>
  )
}

export default Banner
