import styled, { css } from 'styled-components'
import { down } from 'styled-breakpoints'

export const Container = styled.div`
  position: relative;
  height: 600px;
  ${down('sm')} {
    height: 350px;
  }
  ${(props) =>
    props.process &&
    css`
      height: 1800px;
    `}
`

export const Image = styled.img`
  object-fit: cover;
  display: block;
  height: 600px;
  width: 100%;
  ${down('sm')} {
    height: 350px;
  }
  ${(props) =>
    props.process &&
    css`
      height: 1800px;
    `}
`
export const TextContainer = styled.div`
  position: absolute;
  top: 100px;
  left: 148px;
  text-align: center;
  ${down('sm')} {
    top: 40px;
    left: 30px;
  }
`

export const Text = styled.p`
  font-family: Outfit;
  font-size: 72px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  ${down('sm')} {
    font-size: 36px;
  }
`
export const Text2 = styled.p`
  margin: 20px 0;
  width: 50%;
  font-family: Outfit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  ${down('sm')} {
    width: 90%;
  }
  ${(props) =>
    props.subtitle &&
    css`
      margin: 0;
      width: 100%;
      font-size: 43px;
      font-weight: 800;
      color: #fff;
      ${down('sm')} {
        font-size: 16px;
      }
    `}
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`
