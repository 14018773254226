import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { FaBars } from 'react-icons/fa'
import logo from './../../assets/crowneo_navbar.svg'
import { NavLinkScroll } from './styledNavbar'

export const MobileNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  margin-bottom: -20px;
  padding: 20px;
  @media (min-width: 768px) {
    display: none;
  }
`

export const MobileNavList = styled.ul`
  display: flex;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 100vh;
  z-index: 1;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
`

export const MobileNavItem = styled.li`
  width: 100%;
  margin: 1rem 0;
  text-align: center;
`

export const MobileNavLink = styled.a`
  font-family: Outfit;
  font-size: 22px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  color: #000;
  text-decoration: none;
  text-align: left;
  &:hover {
    color: #ccc;
  }
`

export const MobileHamburger = styled(FaBars)`
  color: #000;
  font-size: 30px;
  cursor: pointer;
`

export const MobileLogo = styled.img`
  width: 100px;
  height: 100px;
  margin: 0;
  margin-top: -30px;
  margin-left: 15px;
  padding: 0;
  cursor: pointer;
`

const MobileNavbar = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <MobileNavContainer>
      <MobileLogo src={logo} alt='logo' />
      <MobileHamburger onClick={() => setIsOpen(!isOpen)} />
      <MobileNavList isOpen={isOpen}>
        <MobileNavItem>
          <MobileNavLink href='/#/services'>SERVICIO</MobileNavLink>
        </MobileNavItem>
        <MobileNavItem>
          <MobileNavLink href='/#/process'>PROCESO</MobileNavLink>
        </MobileNavItem>
        <MobileNavItem>
          <MobileNavLink href='/#/us'>NOSOTROS</MobileNavLink>
        </MobileNavItem>
        <MobileNavItem>
          <NavLinkScroll
            activeClass='active'
            to='contactSection'
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            CONTACTO
          </NavLinkScroll>
        </MobileNavItem>
      </MobileNavList>
    </MobileNavContainer>
  )
}

export default MobileNavbar
