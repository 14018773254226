import React from 'react'
import { Formik, Form, Field } from 'formik'
import {
  Button,
  FormContainer,
  Label,
  StyledField,
  SubTitle,
  TextContainer,
  Title,
  YellowLight,
} from './styledContact'
import { ContactBtn } from '../Services/styledServices'
import { Col, Row } from '../../components/Styled/GlobalStyles'
import { collection, addDoc, getDocs } from 'firebase/firestore'
import db from '../../firebase/firebaseConfig'

const submitValues = async (values) => {
  const docRef = await addDoc(collection(db, 'contacts'), values)
  if (docRef.id) {
    window.location.reload()
  }
}

const ContactForm = ({ title, subtitle, isXs }) => {
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    description: '',
    date: '',
  }
  return (
    <>
      <Row style={{ justifyContent: 'center' }}>
        <TextContainer>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
          <YellowLight />
        </TextContainer>
      </Row>
      <FormContainer>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values) => submitValues(values)}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Row>
                <Col>
                  <Label>Nombre:</Label>
                  <StyledField
                    id='name'
                    type='text'
                    name='name'
                    value={values.name}
                    autoComplete='off'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Correo:</Label>
                  <StyledField
                    id='email'
                    type='text'
                    name='email'
                    value={values.email}
                    autoComplete='off'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Telefono:</Label>
                  <StyledField
                    id='phone'
                    type='number'
                    name='phone'
                    value={values.phone}
                    autoComplete='off'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>De que trata tu proyecto?</Label>
                  <StyledField
                    id='description'
                    textArea={true}
                    component='textarea'
                    name='description'
                    value={values.description}
                    autoComplete='off'
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>Cuando te gustaría empezar?</Label>
                  <StyledField
                    id='date'
                    type='date'
                    name='date'
                    value={values.date}
                    autoComplete='off'
                  />
                </Col>
                <Col>
                  <Button type='submit' disabled={isSubmitting}>
                    Enviar
                  </Button>
                </Col>
              </Row>
              {!isXs && (
                <ContactBtn marginBottom='50px' process={true}>
                  Conoce que nos hace diferentes.
                </ContactBtn>
              )}
            </Form>
          )}
        </Formik>
      </FormContainer>
    </>
  )
}

export default ContactForm
