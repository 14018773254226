import React, { useEffect, useRef } from 'react'
import { Col, Row } from '../../components/Styled/GlobalStyles'
import ContactForm from './Form'
import contactImg from '../../assets/contactLogo.svg'
import wassa from '../../assets/whats.svg'
import Info from './Info'
import {
  BannerContainer,
  BgColor,
  RespIcon,
  RespText,
  BorderText,
  Logito,
} from './styledContact'
import logito from './../../assets/singlecrowneo.svg'

const Contact = ({ isXs }) => {
  const contactSecRef = useRef(null)
  useEffect(() => {
    if (window.location.hash === '#contactSection') {
      const targetPosition = contactSecRef.current.offsetTop
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      })
    }
  }, [])
  return (
    <Row id='contactSection' ref={contactSecRef}>
      {isXs && (
        <>
          <Logito src={logito} alt='Crowneo logo single' />
          <BannerContainer>
            <Row>
              <RespText>449-217-3010</RespText>
              <RespIcon />
            </Row>
            <Row style={{ textAlign: 'right' }}>
              <BorderText>Aceptamos stickers*</BorderText>
            </Row>
          </BannerContainer>
        </>
      )}
      <Col size={isXs ? 12 : 8}>
        {/* <BgColor bgColor='#fff'> */}
        <ContactForm
          isXs={isXs}
          title='Contacto'
          subtitle='Estas a un paso de algo increíble'
        />
        {/* </BgColor> */}
      </Col>
      <Col
        size={4}
        style={{
          marginBottom: '50px',
          backgroundColor: '#222',
          zIndex: -9999,
        }}
      >
        {!isXs && (
          <BgColor yellow={true} bgColor='#fff100' border='0 300px 0 0'>
            <Info logo={contactImg} number='449-217-3010' icon={wassa} />
          </BgColor>
        )}
      </Col>
    </Row>
  )
}

export default Contact
