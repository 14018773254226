import styled, { css } from 'styled-components'
import { down } from 'styled-breakpoints'
import {
  gridMarginX,
  mediaMinWidth,
  containerMaxWidth,
  mediaOrder,
  colGutterSize,
  maxGridColumnCount,
} from './gridUtils'
export const RowPadding = styled.div`
  padding: ${(props) => (props.padding ? props.padding : 0)};
`
export const FontColor = styled.span`
  color: ${(props) => (props.color ? props.color : '#000')};
`

const nonFluidStyles = () => {
  return mediaOrder.map(
    (size) => css`
      @media (min-width: ${mediaMinWidth[size]}px) {
        max-width: ${containerMaxWidth[size]}px;
      }
    `,
  )
}

export const Grid = styled.div`
  width: 100%;
  padding-left: ${(props) =>
    typeof props.marginX === 'number' ? props.marginX : gridMarginX}px;
  padding-right: ${(props) =>
    typeof props.marginX === 'number' ? props.marginX : gridMarginX}px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  ${(props) => !props.fluid && nonFluidStyles()}
`
export const getColumnSize = (count) => 100 / (maxGridColumnCount / count)

export const colFlex = (count = maxGridColumnCount) => {
  return css`
    flex: 0 0 ${getColumnSize(count)}%;
    max-width: ${getColumnSize(count)}%;
  `
}

export const colOffset = (count) => css`
  margin-left: ${count === 0 ? 0 : getColumnSize(count)}%;
`

export const colMediaQuery = (media, count, offset) => {
  let mediaText = `(min-width: ${mediaMinWidth[media]}px)`
  if (mediaMinWidth[media + 'Max']) {
    mediaText = `(max-width: ${
      mediaMinWidth[media + 'Max']
    }px) and (min-width: ${mediaMinWidth[media]}px)`
  } else if (media === 'xxs') {
    mediaText = `(max-width: ${mediaMinWidth[media] - 1}px)`
  }
  return css`
    @media ${mediaText} {
      ${count && colFlex(count)};
      ${typeof offset === 'number' &&
      offset < maxGridColumnCount &&
      colOffset(offset)};
    }
  `
}

export const Col = styled.div`
  position: relative;
  width: 100%;

  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;

  ${(props) => props.size && colFlex(props.size)};
  ${(props) =>
    props.offset &&
    props.offset < maxGridColumnCount &&
    colOffset(props.offset)};
  ${(props) =>
    (props.xxs || typeof props.offsetXxs === 'number') &&
    colMediaQuery(mediaOrder[0], props.xxs, props.offsetXxs)};
  ${(props) =>
    (props.xs || typeof props.offsetXs === 'number') &&
    colMediaQuery(mediaOrder[1], props.xs, props.offsetXs)};
  ${(props) =>
    (props.sm || typeof props.offsetSm === 'number') &&
    colMediaQuery(mediaOrder[2], props.sm, props.offsetSm)};
  ${(props) =>
    (props.md || typeof props.offsetMd === 'number') &&
    colMediaQuery(mediaOrder[3], props.md, props.offsetMd)};
  ${(props) =>
    (props.lg || typeof props.offsetLg === 'number') &&
    colMediaQuery(mediaOrder[4], props.lg, props.offsetLg)};
  ${(props) =>
    (props.xl || typeof props.offsetXl === 'number') &&
    colMediaQuery(mediaOrder[5], props.xl, props.offsetXl)};
  ${(props) =>
    (props.xxl || typeof props.offsetXxl === 'number') &&
    colMediaQuery(mediaOrder[6], props.xxl, props.offsetXxl)};
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.wrap ? 'column' : 'row')};
  margin-right: -${(props) => (props.noGutters ? 0 : colGutterSize / 2)}px;
  margin-left: -${(props) => (props.noGutters ? 0 : colGutterSize / 2)}px;
  box-sizing: border-box;

  ${Col} {
    padding-left: ${(props) => (props.noGutters ? 0 : colGutterSize / 2)}px;
    padding-right: ${(props) => (props.noGutters ? 0 : colGutterSize / 2)}px;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
`

export const TextContainer = styled.div`
  margin-top: 150px;
  display: flex;
  margin-left: 50%;
  transform: translateX(-50%);
  ${down('sm')} {
    margin-left: 0;
    transform: translateX(0);
    align-items: center;
    flex-direction: column;
    margin-left: 15%;
  }
`
export const TitleLoCase = styled.h3`
  font-family: Outfit;
  font-size: 42px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: normal;
  color: #000;
  z-index: 99;
  ${down('sm')} {
    font-size: 21px;
  }
`

export const BackTitle = styled.span`
  font-family: Outfit;
  font-size: 104px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: normal;
  color: #fff100;
  z-index: -99;
  margin-left: -180px;
  margin-top: -50px;
  ${down('sm')} {
    font-size: 41px;
    margin-left: 0;
  }
`
