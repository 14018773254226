import React from 'react'
import { DemoContainer, DemoSubtitle, DemoTitle } from './styledSlide'

const DemoTitleText = ({ title, subtitle }) => {
  return (
    <>
      <DemoContainer>
        <DemoTitle>{title}</DemoTitle>
        <DemoSubtitle>{subtitle}</DemoSubtitle>
      </DemoContainer>
    </>
  )
}

export default DemoTitleText
