import React from 'react'
import { NumberText, Underline, UnderText } from './staticsStyled'

const StaticsTexts = ({ nTxt, undNum, undTxt }) => {
  return (
    <>
      <NumberText>
        +{nTxt && `${nTxt + ','}`}
        <Underline>{undNum}</Underline>
      </NumberText>
      <UnderText>{undTxt}</UnderText>
    </>
  )
}

export default StaticsTexts
