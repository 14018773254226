import React from 'react'
import banner from '../../assets/processbanner.svg'
import CenteredImage from '../../components/Images/CenteredImage'
import { Text2 } from '../../components/Images/styledCentered'

const ProcessBanner = () => {
  return (
    <CenteredImage src={banner} alt='Proceso crowneo'>
      Cómo lo,
      <br /> hacemos?
      <Text2>
        Hemos diseñado el proceso de producción de CROWNEO, para que sea lo mas
        sencillo y fácil de seguir de acuerdo a tus necesidades.
      </Text2>
      <Text2>
        Aquí te mostramos los pasos que seguimos para guiarte desde el concepto
        hasta la entrega.
      </Text2>
    </CenteredImage>
  )
}

export default ProcessBanner
