import React from 'react'
import { Col, Row } from '../../../components/Styled/GlobalStyles'
import { Paragraph, ServicesListContainer, Title } from '../styledServices'
import ServicesUl from './ServicesUl'
import { servicesList, photoServices, editServices } from '../../../data'
import { down } from 'styled-breakpoints'
import { useBreakpoint } from 'styled-breakpoints/react-styled'
const ServicesList = () => {
  const isXs = useBreakpoint(down('sm'))
  return (
    <ServicesListContainer>
      <Row
        style={
          isXs
            ? { flexDirection: 'column', backgroundColor: '#222222' }
            : { backgroundColor: '#222222', justifyContent: 'space-evenly' }
        }
      >
        <Col size={isXs ? 12 : 3}>
          <Row>
            <Title>Video</Title>
          </Row>
          <Row>
            <Paragraph>
              Nuestra especialidad, y lo tenemos en muchos sabores y colores.
            </Paragraph>
            <ServicesUl items={servicesList} />
          </Row>
        </Col>
        <Col size={isXs ? 12 : 3}>
          <Row>
            <Title fSize='60px'>Fotografía</Title>
          </Row>
          <Row style={{ marginLeft: isXs ? 0 : '10px' }}>
            <Paragraph>
              Creamos imágenes para fascinar a tus futuros clientes.
            </Paragraph>
            <Paragraph>
              Una vez logrado esto; has dado el primer paso para convencerlos de
              que tomen acción; ya sea generando mas ventas, documentando tu
              evento ó posicionando tu marca.
            </Paragraph>
            <ServicesUl items={photoServices} />
          </Row>
        </Col>
        <Col size={isXs ? 12 : 3}>
          <Row>
            <Title>Edición</Title>
          </Row>
          <Row style={{ marginLeft: isXs ? 0 : '10px' }}>
            <Paragraph>
              Si ya cuentas con material al que te gustaría darle mas uso,
              podemos ayudarte en nuestra sala de edición.
            </Paragraph>
            <Paragraph>
              Estamos en constante actualización y capacitación por lo que tu
              proyecto siempre tendrá el look apropiado y mantendrá vigencia por
              mas tiempo.
            </Paragraph>
            <ServicesUl items={editServices} />
          </Row>
        </Col>
      </Row>
    </ServicesListContainer>
  )
}

export default ServicesList
