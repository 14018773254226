export const servicesList = [
  {
    id: '001',
    title: 'Video Corporativo',
  },
  {
    id: '002',
    title: 'Reels',
  },
  {
    id: '003',
    title: 'Comerciales',
  },
  {
    id: '004',
    title: 'Videos Musicales',
  },
  {
    id: '005',
    title: 'Cortometrajes',
  },
  {
    id: '006',
    title: 'Video Aereo',
  },
  {
    id: '007',
    title: 'Detrás de cámaras',
  },
  {
    id: '008',
    title: 'Documental',
  },
  {
    id: '009',
    title: 'Entrevistas',
  },
  {
    id: '010',
    title: 'Video Social',
  },
  {
    id: '011',
    title: 'Producción en pantalla verde',
  },
  {
    id: '012',
    title: 'Videos de Entrenamiento, capacitación o tutoriales',
  },
  {
    id: '013',
    title: 'En vivos',
  },
]

export const editServices = [
  {
    id: '001',
    title: 'Flujo de trabajo hasta 8K',
  },
  {
    id: '002',
    title: 'Colorimetria',
  },
  {
    id: '003',
    title: 'Motion Graphics',
  },
  {
    id: '004',
    title: 'Diseño Sonoro',
  },
  {
    id: '005',
    title: 'Efectos Visuales',
  },
  {
    id: '006',
    title: 'Procesamiento de material',
  },
  {
    id: '007',
    title: 'Respaldo de material',
  },
  {
    id: '008',
    title: 'Entregas en formato óptimo',
  },
]

export const photoServices = [
  {
    id: '001',
    title: 'Fotografía de producto',
  },
  {
    id: '002',
    title: 'Campañas publicitarias',
  },
  {
    id: '003',
    title: 'Sesión personal',
  },
  {
    id: '004',
    title: 'Estudio Fotográfico equipado',
  },
  {
    id: '005',
    title: 'Fotografía aérea',
  },
  {
    id: '006',
    title: 'Fotografía documental',
  },
  {
    id: '007',
    title: 'Fotografía gastronómica',
  },
  {
    id: '008',
    title: 'Fotografía Social',
  },
]

export const icons = [
  {
    id: '001',
    icon: 'icons/icon_fb.svg',
    name: 'Facebook',
    url: 'https://www.facebook.com/',
  },
  {
    id: '003',
    icon: 'icons/icon_tiktok.svg',
    name: 'Tik Tok',
    url: 'https://www.tiktok.com/',
  },
  {
    id: '004',
    icon: 'icons/icon_youtube.svg',
    name: 'YouTube',
    url: 'https://www.youtbe.com/',
  },
  {
    id: '002',
    icon: 'icons/icon_instagram.svg',
    name: 'Instagram',
    url: 'https://www.instagram.com/',
  },
]
export const logos = [
  {
    id: '001',
    image: 'logos/abinbev.svg',
    name: 'ABInBev',
  },
  {
    id: '002',
    image: 'logos/adidas.svg',
    name: 'Adidas',
  },
  {
    id: '003',
    image: 'logos/agroag.svg',
    name: 'Agroag',
  },
  {
    id: '004',
    image: 'logos/bmwmotorrad.svg',
    name: 'BMW Motorrad',
  },
  {
    id: '005',
    image: 'logos/charlesbeckett.svg',
    name: 'Charles Beckett',
  },
  {
    id: '006',
    image: 'logos/chevrolet.svg',
    name: 'Chevrolet',
  },
  {
    id: '007',
    image: 'logos/grupogarcia.svg',
    name: 'Grupo Garcia',
  },
  {
    id: '008',
    image: 'logos/grupomodelo.svg',
    name: 'Grupo Modelo',
  },
  {
    id: '009',
    image: 'logos/indian.svg',
    name: 'Indian',
  },
  {
    id: '010',
    image: 'logos/necaxa.svg',
    name: 'Necaxa',
  },
  {
    id: '011',
    image: 'logos/peugeot.svg',
    name: 'Peugeot',
  },
  {
    id: '012',
    image: 'logos/procell.svg',
    name: 'Procell',
  },
  {
    id: '013',
    image: 'logos/specialized.svg',
    name: 'Specialized',
  },
  {
    id: '014',
    image: 'logos/taraerolineas.svg',
    name: 'Tara Aerolineas',
  },
  {
    id: '015',
    image: 'logos/vantec.svg',
    name: 'Vantec',
  },
  {
    id: '016',
    image: 'logos/wsbamericas.svg',
    name: 'WSB Americas',
  },
]

export const quotes = [
  {
    id: '0001',
    quote:
      'Más de 8 años trabajando con él y siempre profesional y en constante mejora de equipos y técnicas. Plasma con gran talento la idea a realizar y siempre con la mejor disposición para crear y proponer. Excelente con la fotografía y aún mejor en vídeo.',
    author: '-Charlie Gallardo',
  },
  {
    id: '0002',
    quote:
      'Tienen una alta flexibilidad y adaptabilidad a los requerimientos de los clientes y dan la pauta para la creatividad con el marco técnico y artístico que ustedes cómo expertos dominan. Los tiempos de edición y revisión de proyecto final son buenos y tratan de apegarse al timing del proyecto. En cuanto al antregable es de alta calidad y valor.',
    author: '-Oscar Bermúdez - General Manager Vantec Logistics',
  },
  // {
  //   id: '0003',
  //   quote:
  //     'Hora de divertirnos, aqui es cuando se lleva a cabo la filmación. Siempre listos para movilizarnos a cualquier parte y a cualquier hora.',
  //   author: '-Charlie Gallardo. CEO de Charles Beckett',
  // },
]

export const videoUrls = [
  {
    id: '0001',
    url: 'https://www.youtube.com/watch?v=_Zdnqpne4xM',
    title: 'Video 1',
    thumbnail: 'https://i.ytimg.com/vi/_Zdnqpne4xM/hqdefault.jpg',
  },
  {
    id: '0002',
    url: 'https://youtu.be/TxLM9oevLfo',
    title: 'Video 2',
    thumbnail: 'https://i.ytimg.com/vi/TxLM9oevLfo/hqdefault.jpg',
  },
  {
    id: '0003',
    url: 'https://youtu.be/IZURxEEKC2Q',
    title: 'Video 2',
    thumbnail: 'https://i.ytimg.com/vi/IZURxEEKC2Q/hqdefault.jpg',
  },
  {
    id: '0004',
    url: 'https://youtu.be/NynUeESczOk',
    title: 'Video 2',
    thumbnail: 'https://i.ytimg.com/vi/NynUeESczOk/hqdefault.jpg',
  },
  {
    id: '0005',
    url: 'https://youtu.be/RqUAFy4XXhA',
    title: 'Video 2',
    thumbnail: 'https://i.ytimg.com/vi/RqUAFy4XXhA/hqdefault.jpg',
  },
  {
    id: '0006',
    url: 'https://www.youtube.com/watch?v=b3q2wAzjTzg',
    title: 'Video 2',
    thumbnail: 'https://i.ytimg.com/vi/b3q2wAzjTzg/hqdefault.jpg',
  },
]
