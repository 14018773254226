import React from 'react'
import { ListContainer, ListItem } from '../styledServices'

const ServicesUl = ({ items }) => (
  <ListContainer>
    {items.map((item, index) => (
      <ListItem key={index}>- {item.title}</ListItem>
    ))}
  </ListContainer>
)

export default ServicesUl
