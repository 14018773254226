import React from 'react'
import {
  Container,
  Image,
  Overlay,
  TextContainer,
  Text,
} from './styledCentered'

const CenteredImage = ({ src, alt, children, process = false }) => (
  <Container process={process}>
    <Image process={process} src={src} alt={alt} />
    <Overlay>
      <TextContainer>
        <Text>{children}</Text>
      </TextContainer>
    </Overlay>
  </Container>
)

export default CenteredImage
