import React from 'react'
import styled from 'styled-components'
import crowneoLogo from '../assets/crowneo_footer.svg'

const BlackBackground = styled.div`
  background-color: black;
  height: 100vh;
  display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
`

const Title = styled.h1`
  color: white;
  text-align: center;
`

const Logo = styled.img`
  display: block;
  margin: auto;
`

const ConstructionPage = () => {
  return (
    <BlackBackground>
      <Title>En construcción</Title>
      <Logo src={crowneoLogo} alt='Crowneo Logo' />
    </BlackBackground>
  )
}

export default ConstructionPage
