import styled from 'styled-components'
import { down } from 'styled-breakpoints'

export const Logo = styled.img`
  width: 210px;
  height: auto;
  ${down('sm')} {
    width: 130px;
    margin-left: 50px;
  }
`

export const IconsContainer = styled.div`
  display: flex;
  justify-content: right;
`

export const IconContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 60px;
`

export const IconSVG = styled.img`
  width: 27.4px;
  height: 27.4px;
`
export const FooterContainer = styled.div`
  display: flex;
  margin: 0 60px;
  flex-direction: column;
  ${down('sm')} {
    margin: 0 73px;
  }
  ${down('md')} {
    margin: 0 73px;
  }
`

export const FooterText = styled.p`
  font-family: Outfit;
  font-size: 12px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
  ${down('sm')} {
    text-align: center;
    font-size: 8px;
    margin-top: 10px;
  }
`
// cambiar componente a Link en lugar de a
export const LinkText = styled.a`
  color: #ff00ff;
  cursor: pointer;
`
