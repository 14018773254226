import React from 'react'
import {
  BackTitle,
  Row,
  TextContainer,
  TitleLoCase,
} from '../../components/Styled/GlobalStyles'
import { Container } from './customerStyled'
import { logos } from '../../data'
import Brands from './Brands'
import Statics from '../Statitics/Statics'
import Quote from '../Quotes/Quote'
import DemoVideos from '../SlideVideo/SlideVideo'

const CustomerBanner = ({ isXs }) => {
  return (
    <Container>
      <Row>
        <TextContainer>
          <TitleLoCase>algunos de nuestros clientes</TitleLoCase>
          <BackTitle>amigos</BackTitle>
        </TextContainer>
      </Row>
      <Row>
        <Brands logos={logos} />
      </Row>
      <Statics isXs={isXs} />
      {isXs ? null : <Quote title={'Y esto es lo que dicen:'} />}
      <DemoVideos
        title={'DEMO'}
        subtitle={'porque sabemos que quieres ver los resultados'}
        isXs={isXs}
      />
    </Container>
  )
}

export default CustomerBanner
