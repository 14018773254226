import React, { Fragment } from 'react'
import './App.css'
import { BrowserRouter as Router, Route, HashRouter } from 'react-router-dom'
import { routes } from './routes'
import { ThemeProvider } from 'styled-components'
import { Normalize } from "styled-normalize";

const theme = {
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
}

export default function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        {/* <Normalize /> */}
        <HashRouter>
          {routes.map((route, index) => {
            return route.component ? (
              <Route
                exact
                path={route.path}
                public={route.public}
                component={route.component}
              />
            ) : null
          })}
        </HashRouter>
      </ThemeProvider>
    </>
  )
}
