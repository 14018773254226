import React from 'react'
import { Row, Col } from './../Styled/GlobalStyles'
import logo from './../../assets/crowneo_footer.svg'
import {
  IconSVG,
  IconContainer,
  IconsContainer,
  Logo,
  FooterContainer,
  FooterText,
  LinkText,
} from './styledFooter'
import { icons } from '../../data'

const Footer = ({ isXs }) => {
  return (
    <Row
      style={{
        background: '#39383d',
        padding: isXs ? '30px 0 40px 30px' : '100px 0 40px 240px',
      }}
    >
      {!isXs && (
        <Col>
          <Logo src={logo} alt='Crowneo Creative' />
        </Col>
      )}
      <Col>
        <FooterContainer>
          <IconsContainer>
            {icons.map((icon, index) => {
              return (
                <IconContainer key={index}>
                  <IconSVG src={icon.icon} alt={icon.name} />
                </IconContainer>
              )
            })}
          </IconsContainer>
          {isXs && <Logo src={logo} alt='Crowneo Creative' />}
          <FooterText>
            Video Producción Personalizada para Todos. <br />
            © 2023 CROWNEO/creative. Todos los derechos reservados
          </FooterText>
          <FooterText>
            Powered by <LinkText>@lDanielaeiou</LinkText>
          </FooterText>
        </FooterContainer>
      </Col>
    </Row>
  )
}

export default Footer
