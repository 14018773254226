import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { Col, Row } from '../../components/Styled/GlobalStyles'
import {
  ButtonContainer,
  VideoContainer,
  Thumbnail,
  ImageContainer,
  ImageOverlay,
} from './styledSlide'

const VideoSection = ({ data, isXs }) => {
  const [videoPlay, setVideoPlay] = useState(false)
  const onClickChange = (e) => {
    setVideoPlay(e)
  }
  const videoUrl = data.find((video) => video.id === videoPlay)
  return (
    <>
      <VideoContainer>
        <ReactPlayer
          url={
            videoUrl
              ? videoUrl.url
              : 'https://youtu.be/QSUaC1qZIvA'
          }
          width={isXs ? '318px' : '1007px'}
          height={isXs ? '180.5px' : '617px'}
          pip={true}
          controls={true}
          playing={false}
        />
      </VideoContainer>
      {isXs ? (
        <Row style={{ padding: '0 40px' }}>
          {data.map((video) => (
            <Col size={6}>
              <ImageContainer
                onClick={(e) => onClickChange(video.id)}
                key={video.id}
                value={video.id}
              >
                <Thumbnail src={video.thumbnail} alt={video.title} />
                {isXs ? null : <ImageOverlay className='image-overlay' />}
              </ImageContainer>
            </Col>
          ))}
        </Row>
      ) : (
        <ButtonContainer>
          {data.map((video) => (
            <ImageContainer
              onClick={(e) => onClickChange(video.id)}
              key={video.id}
              value={video.id}
            >
              <Thumbnail src={video.thumbnail} alt={video.title} />
              <ImageOverlay className='image-overlay' />
            </ImageContainer>
          ))}
        </ButtonContainer>
      )}
    </>
  )
}

export default VideoSection
