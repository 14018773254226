import React from 'react'
import { Col, Row } from '../../../components/Styled/GlobalStyles'
import {
  BottomSvsCont,
  ContactBtn,
  DiagonalBorder,
  Oval,
  SvsBottom,
  SvsContainer,
  SvsImg,
  SvsTxt,
} from '../styledServices'
import filmIcon from '../../../assets/filmicon.svg'

const ServicesBottom = ({ text }) => {
  return (
    <>
      <SvsContainer />
      <SvsContainer border={true} />
      <Oval />
      <SvsBottom>
        <SvsImg src={filmIcon} alt='Film services crowneo' />
      </SvsBottom>
      <BottomSvsCont>
        <SvsTxt>{text}</SvsTxt>
        <ContactBtn>Contacto</ContactBtn>
      </BottomSvsCont>
    </>
  )
}

export default ServicesBottom
