import React from 'react'
import banner from '../../../assets/services.svg'
import CenteredImage from '../../../components/Images/CenteredImage'
import { Text2 } from '../../../components/Images/styledCentered'

const Banner = () => {
  return (
    <CenteredImage src={banner} alt='Nuestros servicios'>
      Mas que video,
      <br /> hacemos magia
      <Text2>
        Nuestra especialidad es hacer videos que le quieras presumir a tus
        amigos, pero hacemos más que eso.
      </Text2>
      <Text2 subtitle>Conoce todos nuestros servicios.</Text2>
    </CenteredImage>
  )
}

export default Banner
