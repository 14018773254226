import React from 'react'
import Footer from './Footer/Footer'
import Navbar from './Navbar/Navbar'

const Layout = ({ children, isXs }) => {
  return (
    <>
      <Navbar />
      {children}
      <Footer isXs={isXs} />
    </>
  )
}

export default Layout
