import styled from 'styled-components'
import bgImage from './../../assets/doodlessinletras.svg'
import filmImage from './../../assets/film.svg'
import { down } from 'styled-breakpoints'

export const Background = styled.div`
  background-color: #222;
  background-image: url(${bgImage});
  background-size: 50%;
  z-index: -9;
  width: 100%;
  height: 100%;
  ${down('sm')} {
    background-size: 100%;
  }
`

export const Title = styled.h3`
  font-family: Outfit;
  font-size: 80px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.84;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 50px 127px;
  ${down('sm')} {
    padding: 50px 30px;
    font-size: 67px;
  }
`
export const Paragraph = styled.p`
  font-family: Outfit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 0 127px;
  ${down('sm')} {
    padding: 0 30px;
    font-size: 13px;
  }
`

export const ColImg = styled.div`
  background-image: url(${filmImage});
  background-size: cover;
  width: 100%;
  margin-left: -8px;
  height: 100%;
`

export const Image = styled.img`
  width: 10%;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: -60px;
  ${down('sm')} {
    width: 30%;
    margin-top: -30px;
  }
`
