import React from 'react'
import { Row } from '../../components/Styled/GlobalStyles'
import { SeparatorTxt } from './separatorStyled'

const Separator = ({ txt }) => {
  return (
    <Row style={{ justifyContent: 'center' }}>
      <SeparatorTxt>{txt}</SeparatorTxt>
    </Row>
  )
}

export default Separator
