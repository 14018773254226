import styled, { css } from 'styled-components'
import { up, only, down, between } from 'styled-breakpoints'

export const VideoContainer = styled.div`
  width: 100%;
  /* top right bottom left */
  margin: 0 139px 139px 139px;
  padding-bottom: 56.25%;
  background-color: none transparent;
  height: 0;
  ${down('sm')} {
    margin-top: -61px;
    /* height: 480px; */
    padding-bottom: 177%;
    margin: 0 139px 139px 0px;
  }
`

export const VideoWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  z-index: -99;
  ${down('sm')} {
    height: 100%;
    padding-bottom: 177%;
  }
`
export const Video = styled.iframe`
  position: absolute;
  top: -50px;
  left: -139px;
  width: 100%;
  height: 100%;
  background-color: white !important;
  ${down('sm')} {
    width: 100%;
    height: 100vh;
  }
`
export const TextContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 50%;
  top: 50%;
  transform: translate(-90%, -45%);
  align-items: center;
  ${down('sm')} {
    transform: translate(-90%, -40%);
    margin-left: 139px;
  }
`
export const VideoText = styled.p`
  font-family: Poppins;
  font-size: ${(props) => props.fSize || '118px'};
  font-weight: 900;
  color: ${(props) => props.fColor || '#fff'};
  text-align: center;
  ${(props) => props.mTop && 'margin-top: -70px;'}
  ${(props) =>
    props.smSize &&
    css`
      color: #fff;
      text-align: right;
      margin-left: 100%;
    `}
    ${down('sm')} {
    font-size: 112px;
  }
`
export const VideoButton = styled.button`
  width: 130px;
  height: 40px;
  border-radius: 35px;
  background-color: #fff100;
  font-family: Outfit;
  font-size: 20px;
  font-weight: 800;
  line-height: 1.25;
  text-align: center;
  color: #252429;
  ${down('sm')} {
    margin-top: 60px;
  }
`

export const SmallText = styled.span`
  position: absolute;
  font-family: Poppins;
  font-size: 9px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  top: 54.7%;
  left: 55.3%;
  transform: translate(-30%, -50%);
  align-items: center;
  ${down('sm')} {
    font-size: 12px;
    left: 32%;
    top: 72%;
  }
`

export const Icon = styled.img`
  width: 22.5px;
  height: 28px;
  margin-top: 170px;
  ${down('sm')} {
    display: none;
  }
`
