import React from 'react'
import { Row } from '../../components/Styled/GlobalStyles'
import DemoTitleText from './DemoTitle'
import VideoSection from './VideoSection'
import { videoUrls } from '../../data'
import QuoteSection from './QuoteVideo'

const DemoVideos = ({ title, subtitle, isXs }) => {
  return (
    <>
      <Row>
        <DemoTitleText title={title} subtitle={subtitle} />
      </Row>
      <Row style={{ background: '#222222' }}>
        <VideoSection data={videoUrls} isXs={isXs} />
      </Row>
      <Row
        style={{
          background: '#222222',
          justifyContent: 'center',
          padding: isXs ? '30px 0' : '90px 0',
        }}
      >
        <QuoteSection />
      </Row>
    </>
  )
}

export default DemoVideos
