import React from 'react'
import { Col, FontColor, Row } from '../../components/Styled/GlobalStyles'
import { Background, ColImg, Image, Paragraph, Title } from './makeItStyled'
import separator from './../../assets/separator.svg'

const MakeIt = ({ isXs }) => {
  return (
    <>
      <Background>
        <Row>
          <Col
            style={{
              paddingBottom: '50px',
              backgroundColor: 'rgba(100, 100, 100, 0.1)',
            }}
          >
            <Title>
              Hazlo diferente<FontColor color='#fff100'>.</FontColor>
            </Title>
            <Paragraph>
              Entre tanto contenido es necesario hacer algo que te diferencie de
              los demás, y esa es nuestra especialidad.
              <br />
              <br />
              Sabemos que tu proyecto es único, por lo que necesita un equipo
              que haga las cosas diferente y nosotros podemos con el reto.
              <br />
              <br />
              Después de crear más de 1000 videos hemos aprendido mucho de
              nuestros clientes y con esta experiencia, te ayudamos a cumplir
              tus objetivos con videos que den de que hablar.
              <br />
              <br />
              Si puedes pensarlo, podemos crearlo; Así de fácil. Somos
              extremadamente versátiles y podemos realizar cualquier producción
              en un estilo visual que no haz experimentado.
              <br />
              <br />
              Así que empecemos a crear algo único.
            </Paragraph>
          </Col>
          <Col>
            <ColImg />
          </Col>
        </Row>
      </Background>
      <Row>
        <Image src={separator} />
      </Row>
    </>
  )
}

export default MakeIt
