import React from 'react'
import {
  VideoContainer,
  VideoWrapper,
  Video,
  VideoText,
  TextContainer,
  VideoButton,
  Icon,
  SmallText,
} from './headerStyled'
import headerVideo from './../../assets/Landing6000.mp4'
import verticalVideo from './../../assets/ShowreelVertical.mp4'
import arrows from './../../assets/iconarrows.svg'
import { useRef } from 'react'
import { useEffect } from 'react'

const Header = ({ isXs }) => {
  const iframeRef = useRef(null)
  useEffect(() => {
    const iframeDoc =
      iframeRef.current.contentDocument ||
      iframeRef.current.contentWindow.document
    iframeDoc.body.style.backgroundColor = 'white'
  }, [])
  return (
    <>
      <VideoContainer>
        <VideoWrapper>
          <Video
            ref={iframeRef}
            allowtransparency='true'
            style={
              isXs ? { marginLeft: '139px' } : { backgroundColor: 'white' }
            }
            src={isXs ? verticalVideo : headerVideo}
            frameBorder='0'
            sandbox='allow-scripts allow-same-origin'
            // allow='autoplay;'
            loop
            allowFullScreen
            autoPlay
          />
          <SmallText>En serio… para todo.</SmallText>
          <TextContainer>
            <VideoText>VIDEO</VideoText>
            <VideoText fSize={'98px'} mTop={true}>
              PARA
            </VideoText>
            <VideoText fSize={'143px'} fColor='#fff100' mTop={true}>
              TODO
            </VideoText>
            <VideoButton>Rétanos.</VideoButton>
            <Icon src={arrows} />
          </TextContainer>
        </VideoWrapper>
      </VideoContainer>
    </>
  )
}

export default Header
