import React from 'react'
import Layout from '../../../components/Layout'
import Banner from '../ServicesBanner/Banner'
import ServicesBottom from '../ServicesBanner/ServicesBottom'
import ServicesList from '../ServicesBanner/ServicesList'
import { down } from 'styled-breakpoints'
import { useBreakpoint } from 'styled-breakpoints/react-styled'

const Services = () => {
  const isXs = useBreakpoint(down('sm'))
  return (
    <Layout isXs={isXs}>
      <Banner />
      <ServicesList />
      <ServicesBottom text='Hacemos de todo, rétanos.' />
    </Layout>
  )
}

export default Services
