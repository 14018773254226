import React from 'react'
import { ColorQuote, TitleQuote } from './styledSlide'

const QuoteSection = () => {
  return (
    <>
      <TitleQuote>
        Ponle botón de <ColorQuote>play</ColorQuote> a tu historia
      </TitleQuote>
    </>
  )
}

export default QuoteSection
