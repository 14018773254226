import React from 'react'
import { Row } from '../../components/Styled/GlobalStyles'
import { Icon, InfoText } from './styledContact'

const Info = ({ number, icon }) => {
  return (
    <>
      <Row style={{ position: 'absolute', bottom: 50, left: '30px' }}>
        <Row>
          <InfoText bottom='30px'>{number}</InfoText>
          <Icon src={icon} alt='whatsapp' />
        </Row>
        <Row>
          <InfoText fSize='24px'>
            Prefieres hablar? Puedes llamarnos 24/7
          </InfoText>
        </Row>
        <Row>
          <InfoText fSize='15px'>Aceptamos stickers*</InfoText>
        </Row>
      </Row>
    </>
  )
}

export default Info
