import styled, { css } from 'styled-components'
import { Button } from 'reactstrap'
import { down } from 'styled-breakpoints'

export const ServicesContainer = styled.div`
  position: relative;
  height: 600px;
`
export const Image = styled.img`
  object-fit: cover;
  display: block;
  height: 600px;
  width: 100%;
`

export const TextContainer = styled.div`
  position: absolute;
  top: 100px;
  left: 148px;
  text-align: center;
`

export const Text = styled.p`
  font-family: Outfit;
  font-size: 72px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`
export const Text2 = styled.p`
  margin: 20px 0;
  width: 50%;
  font-family: Outfit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  ${(props) =>
    props.subtitle &&
    css`
      margin: 0;
      width: 100%;
      font-size: 43px;
      font-weight: 800;
      color: #fff;
    `}
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`
export const Title = styled.h3`
  margin-top: -60px;
  font-family: Outfit;
  font-size: 91px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: normal;
  text-align: center;
  color: #fff100;
  ${down('sm')} {
    margin-top: -40px;
    font-size: 53px;
  }
  ${(props) =>
    props.fSize &&
    css`
      font-size: ${props.fSize};
      margin-top: -40px;
    `}
`

export const Paragraph = styled.p`
  padding: 20px 0;
  font-family: Outfit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`
export const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  ${down('sm')} {
    margin-bottom: 50px;
  }
`
export const ListItem = styled.li`
  font-family: Outfit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-bottom: 4px;
`
export const ServicesListContainer = styled.div`
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 126px;
  background-color: #222222;
  margin: 0 0 -4px 0;
  ${down('sm')} {
    padding: 0 50px;
  }
`

export const SvsContainer = styled.div`
  position: relative;
  z-index: 3;
  background-color: #222222;
  height: 200px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 10%);
  ${(props) =>
    props.border &&
    css`
      height: 200px;
      z-index: 2;
      background-color: #fff100;
      margin-top: -180px;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 10%);
    `}
`

export const SvsBottom = styled.section`
  background-color: white;
`
export const DiagonalBorder = styled.div``

export const SvsTxt = styled.p`
  font-family: Outfit;
  font-size: 54px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: normal;
  color: #000;
  margin-top: -50px;
  margin-left: 150px;
`
export const Oval = styled.div`
  width: 128.2px;
  height: 200px;
  border-radius: 60px;
  background-color: #fbed1d;
  position: absolute;
  right: 400px;
  margin-top: -150px;
`

export const BottomSvsCont = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`

export const SvsImg = styled.img`
  width: 100px;
  height: auto;
  position: absolute;
  right: 390px;
  margin-top: -50px;
`

export const ContactBtn = styled(Button)`
  width: 200px;
  height: 57px;
  padding: 0 20px;
  background-color: #fbed1d;
  font-family: Outfit;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  border-radius: 20px;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  ${(props) =>
    props.process &&
    css`
      ${down('sm')} {
        font-size: 8px;
        padding: 0 10px;
        height: 27px;
        ${(props) =>
          props.fSize &&
          css`
            font-size: ${props.fSize};
            height: 37px;
          `}
      }
      width: 100%;
      border-radius: 80px;
      text-decoration: underline;
      margin-bottom: ${props.marginBottom && props.marginBottom};
    `}
`
