import React from 'react'
import {
  Background,
  Column,
  Image,
  ImageContainer,
  Text,
  TitleContainer,
} from './usStyled'
import serx from './../../assets/us/serxpicture.svg'
import icon from './../../assets/us/iconcrowneo.svg'
const UsHeader = ({ isXs }) => {
  return (
    <Background>
      <Column>
        <TitleContainer>
          <Text title={true}>Hola, mucho gusto.</Text>
        </TitleContainer>
        <Text>
          Desde 2017 somos una casa productora de servicio completo y totalmente
          personalizado.
        </Text>
        <Text>
          Nos metimos en este negocio por qué disfrutamos contar historias
          convincentes de manera creativa, solucionar problemas y divertirnos
          haciendolo.
        </Text>
        <Text>
          Creemos firmemente que el video es la herramienta mas util para un
          negocio y sabemos que si el proceso es interesante, los resultados
          también lo serán.
        </Text>
        <Text>
          Nos ubicamos en Aguascalientes, Ags. y tenemos cobertura en todo
          México; incluso hemos trabajado con empresas fuera del país
        </Text>
      </Column>
      {!isXs && (
        <Column>
          <ImageContainer>
            <Image src={serx} alt='Crowneo Founder' />
            <Image icon={true} src={icon} alt='Crowneo Founder' />
            <Text icon={true}>Sergio Alba, Fundador</Text>
          </ImageContainer>
        </Column>
      )}
    </Background>
  )
}

export default UsHeader
