import React from 'react'
import Layout from '../../components/Layout'
import ProcessBanner from './ProcessBanner'
import ProcessInfo from './ProcessInfo'
import Separator from './Separator'
import { down } from 'styled-breakpoints'
import { useBreakpoint } from 'styled-breakpoints/react-styled'

const Process = () => {
  const isXs = useBreakpoint(down('sm'))
  return (
    <>
      <Layout isXs={isXs}>
        <ProcessBanner />
        <Separator txt='hacemos que parezca fácil' />
        <ProcessInfo />
      </Layout>
    </>
  )
}

export default Process
