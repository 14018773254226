import React from 'react'
import { Row } from '../../components/Styled/GlobalStyles'
import { BannerText } from './bannerStyled'

const BannerMobile = () => {
  return (
    <Row style={{ display: 'flex', flexDirection: 'column' }}>
      <BannerText title={true}>Qué podemos hacer?</BannerText>
      <BannerText>Comerciales</BannerText>
      <BannerText>Documental</BannerText>
      <BannerText>Campañas</BannerText>
      <BannerText>Musical</BannerText>
      <BannerText>Coorporativo</BannerText>
      <BannerText>Sociales</BannerText>
      <BannerText>Reels</BannerText>
      <BannerText>Foto de producto</BannerText>
      <BannerText>Animación</BannerText>
      <BannerText>Video Aereo</BannerText>
      <BannerText>En vivos</BannerText>
      <BannerText>Pantalla Verde</BannerText>
      <Row>
        <BannerText pizza={true}>...Pizza?</BannerText>
      </Row>
    </Row>
  )
}

export default BannerMobile
