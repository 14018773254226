import styled, { css } from 'styled-components'
import processBg from '../../assets/bgProcess.svg'
import { down } from 'styled-breakpoints'

export const SeparatorTxt = styled.div`
  padding: 20px 0;
  font-family: Outfit;
  font-size: 75px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  ${down('sm')} {
    font-size: 48px;
  }
`

export const ProcessContainer = styled.div`
  background-image: url(${processBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(
    255,
    0,
    0,
    0.5
  ); /* Red background color with 50% opacity */
  height: 900px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
`
export const TextRow = styled.p`
  padding: 0 300px;
  font-size: 22px;
  text-align: center;
  margin: 0;
  margin-bottom: 60px;
  ${down('sm')} {
    padding: 0 20px;
    margin-bottom: 10px;
  }
`

export const TwoColumnRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 40px;
  margin-bottom: 30px;
  ${down('sm')} {
    width: 100%;
    flex-direction: column;
  }
`

export const Column = styled.div`
  width: 45%;
  text-align: center;
  ${down('sm')} {
    width: 90%;
  }
`

export const Text = styled.p`
  font-family: Outfit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  ${down('sm')} {
    font-size: 13px;
  }
  ${(props) =>
    props.title &&
    css`
      ${down('sm')} {
        font-size: 40px;
      }
      font-size: 40px;
      font-weight: 800;
      line-height: 1.25;
      text-align: center;
      color: #fff100;
    `}
`
