import React from 'react'
import { Col, Row, TitleLoCase } from '../../components/Styled/GlobalStyles'
import { quotes } from '../../data'
import { Author, Paragraph, TextContainer } from './styledQuote'

const Quote = ({ title }) => {
  return (
    <>
      <Row style={{ justifyContent: 'center', margin: '100px 0' }}>
        <TextContainer>
          <TitleLoCase>{title}</TitleLoCase>
        </TextContainer>
      </Row>
      <Row>
        {quotes.map((q, index) => (
          <Col>
            <TextContainer>
              <Paragraph key={index}>{q.quote}</Paragraph>
              <Author>{q.author}</Author>
            </TextContainer>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default Quote
