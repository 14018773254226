import React from 'react'
import Layout from '../components/Layout'
import Banner from './AfterBanner/Banner'
import Contact from './Contact/Contact'
import CustomerBanner from './Customers/CustomerBanner'
import Header from './Header/Header'
import MakeIt from './MakeItDifferent/MakeIt'
import { down } from 'styled-breakpoints'
import { useBreakpoint } from 'styled-breakpoints/react-styled'
import ConstructionPage from './Construction'

const HomePage = () => {
  const isXs = useBreakpoint(down('sm'))
  const isMid = useBreakpoint(down('md'))
  return (
    <>
      <Layout isXs={isXs} isMid={isMid}>
        {/* <ConstructionPage /> */}
        <Header isXs={isXs} />
        <Banner />
        <MakeIt isXs={isXs} />
        <CustomerBanner isXs={isXs} />
        <Contact isXs={isXs} />
      </Layout>
    </>
  )
}

export default HomePage
