import styled, { keyframes } from 'styled-components'
import { down } from 'styled-breakpoints'

export const ImageContainer = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${down('sm')} {
    width: 40px;
    height: 40px;
  }
  ${down('md')} {
    width: 100px;
    height: 100px;
  }
`
export const SlideImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  ${down('sm')} {
    max-width: 70%;
    max-height: 70%;
  }
`

export const TextContainer = styled.div`
  width: 400px;
  height: auto;
  font-size: calc(
    32px + (18 - 12) * ((100vw - 300px) / (1600 - 300))
  ); /* adjust font size based on container width */
  line-height: calc(1.2em + (1.5 - 1.2) * ((100vh - 200px) / (1200 - 200)));
`

export const SlideTxt = styled.p`
  margin: 0;
  opacity: 0.42;
  font-family: Outfit;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  ${down('sm')} {
    opacity: 1;
    margin-top: 10px;
    ${(props) => props.title && 'font-size: 34px; margin-bottom: 20px;'}
    ${(props) => props.pizza && 'margin-left: 130%'}
  }
`
