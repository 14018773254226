import styled from 'styled-components'
import { down } from 'styled-breakpoints'

export const ButtonContainer = styled.div`
  width: 1007px;
  display: flex;
  margin-left: 50%;
  transform: translateX(-50%);
  border-top: 10px solid #000;
  border-bottom: 10px solid #000;
  ${down('sm')} {
    flex-direction: column;
  }
`

export const VideoContainer = styled.div`
  width: 1007px;
  height: 617px;
  margin: auto;
  margin-left: 50%;
  margin-top: -160px;
  transform: translateX(-50%);
  ${down('sm')} {
    margin-top: -170px;
    height: 200px;
  }
`
export const DemoContainer = styled.div`
  margin-left: 50%;
  transform: translateX(-50%);
  padding-bottom: 200px;
`

export const DemoTitle = styled.p`
  font-family: Poppins;
  font-size: 275px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff100;
  ${down('sm')} {
    font-size: 96px;
  }
`
export const DemoSubtitle = styled.p`
  font-size: 38px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-top: -29%;
  margin-left: 10px;
  ${down('sm')} {
    font-size: 13px;
  }
`
export const ImageContainer = styled.div`
  position: relative;
  &:hover .image-overlay {
    cursor: pointer;
    opacity: 0;
  }
  ${down('sm')} {
    height: 87.2px;
    margin-bottom: 40px;
  }
`
export const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff100;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
`
export const Thumbnail = styled.img`
  width: 168px;
  height: 90px;
  object-fit: cover;
  cursor: pointer;
`
export const TitleQuote = styled.p`
  font-family: Outfit;
  font-size: 51px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  ${down('sm')} {
    font-size: 15px;
  }
`
export const ColorQuote = styled.span`
  color: #fff100;
`
